import { useMeDetails } from "./user";
import { meetingService } from "@/core/services";
import { useQuery } from "@tanstack/vue-query";
import { usePrevious } from "@vueuse/core";

export const useMeetingAccessRights = () => {
  const userId = useMe();
  const meetingId = useMeetingId();
  return useQuery({
    queryKey: computed(() => ["access_rights", userId.value, meetingId.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsPublicAccessRightsRetrieve({
          uuid: meetingId.value!,
        }),
      staleTime: Infinity,
    });
};

export const useUserAccessRights = () => {
  const userId = useMe();
  return useQuery({
    queryKey: computed(() => ["access_rights", userId.value]),
    queryFn: () =>
      meetingService.getInstance().getUsersApi().usersAccessRightsRetrieve({
        uuid: userId.value!,
      }),
    staleTime: Infinity,
  });
};

export const usePermission = () => {
  const meetingId = useMeetingId();
  const { data: meetingAccessRights, refetch } = useMeetingAccessRights();
  const { data: userAccessRights } = useUserAccessRights();
  const { data: user } = useMeDetails();
  const { data: currentMeeting } = usePublicMeeting();

  const previousMeeting = usePrevious(meetingId);

  watch(meetingId, () => {
    if (meetingId != null && previousMeeting.value !== meetingId.value) {
      refetch();
    }
  });

  const store = useUserStore();

  const { setModules, setMeetingPermissions, setUserPermissions, setIsSuperUser } = store;

  watch(userAccessRights, () => {
    if (userAccessRights.value) {
      setUserPermissions(
        new Set([...(userAccessRights.value?.access_rights ?? [])]),
      );
    }
  });

  watch(user, () => {
    if (user.value?.is_superuser) {
      setIsSuperUser();
    }
  });

  watch(meetingAccessRights, () => {
    if (meetingId.value != null && meetingAccessRights.value) {
      setMeetingPermissions(
        new Set([...(meetingAccessRights.value?.access_rights ?? [])]),
        meetingId.value,
      );
    }
  });

  watch(currentMeeting, () => {
    if (currentMeeting.value) {
      setModules(
        new Set(
          currentMeeting.value?.modules.map((module) => module.codename) ?? [],
        ),
        currentMeeting.value.id,
      );
    }
  });

  const { hasPermission } = storeToRefs(store);

  return hasPermission;
};
